<template>
  <div style="min-height: 200px">
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
        <tr>
          <th class="text-center">SL No.</th>
          <th>Customer Name</th>
          <th>Contact No</th>
          <th v-if="hasCustomerLocation">Customer Location</th>
          <th v-if="hasCustomerGender">Gender</th>
          <th v-if="hasHasCustomerAge">Age</th>
          <th class="text-center">Sales Qty</th>
          <th class="text-center">Total Amount</th>
          <th class="text-center">Vat Amount</th>
          <th class="text-center">Sales Amount</th>
          <th class="text-center">COGS</th>
          <th class="text-center">Gross Profit</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in tableItems" :key="i">
            <td width="10%" class="text-center">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
            <td>{{ item.customer_name }}</td>
            <td>{{ item.phone }}</td>
            <td v-if="hasCustomerLocation">{{ item.customer_location }}</td>
            <td v-if="hasCustomerGender">{{ item.customer_gender }}</td>
            <td v-if="hasHasCustomerAge">{{ item.customer_age }} </td>
            <td width="15%" class="text-right">{{ item.net_quantity }}</td>
            <td width="15%" class="text-right">{{ item.total_sale_amount ? commaFormat(item.total_sale_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.total_vat_amount ? commaFormat(item.total_vat_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.sales_amount ? commaFormat(item.sales_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.stocks_sum_stock_value ? commaFormat(item.stocks_sum_stock_value) : '' }}</td>
            <td width="15%" class="text-right">{{ item.stocks_sum_profit_value ? commaFormat(item.stocks_sum_profit_value) : '' }}</td>
          </tr>
          <tr v-if="currentPage === lastPage">
            <td colspan="3" class="text-right">Total</td>
            <td v-if="hasCustomerLocation"></td>
            <td v-if="hasCustomerGender"></td>
            <td v-if="hasHasCustomerAge"></td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_net_quantity }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_sale_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_vat_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_sales_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.stocks_sum_stock_value }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.stocks_sum_profit_value }}</td>
          </tr>
        </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";
import figureFormatter from "@/services/utils/figureFormatter";

const {commaFormat} = figureFormatter();
const props = defineProps({
  tableItems: {
    type: Array,
  },
  currentPage: Number,
  lastPage: Number,
  perPage: Number,
  totalValue: Object,
})

const hasCustomerLocation = computed(() => {
  return props.tableItems.some(item => item.customer_location && item.customer_location.trim() !== '');
});

const hasCustomerGender = computed(() => {
  return props.tableItems.some(item => item.customer_gender && item.customer_gender.trim() !== '');
});

const hasHasCustomerAge = computed(() => {
  return props.tableItems.some(item => item.customer_age && item.customer_age.trim() !== '');
});


</script>

<template>
  <div style="min-height: 200px">
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
        <tr>
          <th class="text-center">SL No.</th>
          <th>Item</th>
          <th class="text-center">Sales Qty</th>
          <th class="text-center">Total Amount</th>
          <th class="text-center">Vat Amount</th>
          <th class="text-center">Sales Amount</th>
          <th class="text-center">COGS</th>
          <th class="text-center">Gross Profit</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in tableItems" :key="i">
            <td width="10%" class="text-center">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
            <td>{{ item.product_name }}</td>
            <td width="15%" class="text-right">{{ item.net_quantity }}</td>
            <td width="15%" class="text-right">{{ item.total_sale_amount ? commaFormat(item.total_sale_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.total_vat_amount ? commaFormat(item.total_vat_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.sales_amount ? commaFormat(item.sales_amount) : '' }}</td>
            <td width="15%" class="text-right">{{ item.stocks_sum_stock_value ? commaFormat(item.stocks_sum_stock_value) : '' }}</td>
            <td width="15%" class="text-right">{{ item.stocks_sum_profit_value ?  commaFormat(item.stocks_sum_profit_value) : ''  }}</td>
          </tr>
           <tr v-if="currentPage === lastPage">
            <td colspan="2" class="text-right"> Total </td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_net_quantity }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_sale_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_vat_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.total_sales_amount }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.stocks_sum_stock_value }}</td>
            <td width="15%" class="text-right"> {{ totalValue && totalValue.stocks_sum_profit_value }}</td>
          </tr>
        </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const {commaFormat} = figureFormatter();

const props = defineProps({
  tableItems: {
    type: Array,
  },
  currentPage: Number,
  lastPage: Number,
  perPage: Number,
  totalValue: Object,
})

</script>

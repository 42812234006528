<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Date</th>
                        <th>Invoice Number</th>
                        <th v-if="false">Invoice Type</th>
                        <th v-if="false">Order Type</th>
                        <th v-if="contactName">Client Name</th>
                        <th v-if="hasBusinessName">Business Unit</th>
                        <th v-if="salePersonName">Sales Person</th>
                        <th>Invoice Amount</th>
                        <th>VAT Amount</th>
                        <th>Total Amount</th>
                        <th>Discount Amount</th>
                        <th>Net Bill Amount</th>
                        <th>Paid Amount</th>
                        <th>Due Amount</th>
                        <th>COGS</th>
                        <th>Gross Profit</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                        <td>{{ item.date }} </td>
                        <td>{{ item.bill_number }}</td>
                        <td v-if="false">{{ item.invoice_type }}</td>
                        <td v-if="false">{{ item.sales_order_master ? item.sales_order_master.sale_type_title : '' }}</td>
                        <td v-if="contactName">{{ item.contact_name }}</td>
                        <td v-if="hasBusinessName">{{ item.business_name }}</td>
                        <td v-if="salePersonName">{{ item.sr_name }}</td>
                        <td class="text-right">{{ item.subtotal_amount ? commaFormat(item.subtotal_amount) : '' }}</td>
                        <td class="text-right">{{ item.vat_amount ? commaFormat(item.vat_amount) : '' }}</td>
                        <td class="text-right">{{  commaFormat((item.subtotal_amount ?? 0) + (item.vat_amount ?? 0)) }}</td>
                        <td class="text-right">{{ item.total_discount ? commaFormat(item.total_discount) : '' }}</td>
                        <td class="text-right">{{ item.total_amount ? commaFormat(item.total_amount) : '' }}</td>
                        <td class="text-right">{{ item.paid_amount ? commaFormat(item.paid_amount) : '' }}</td>
                        <td class="text-right">{{ getTotalDue(item) ? commaFormat(getTotalDue(item)) : '' }}</td>
                        <td class="text-right">{{ item.stocks_sum_stock_value ? commaFormat(item.stocks_sum_stock_value) : '' }}</td>
                        <td class="text-right">{{ item.stocks_sum_profit_value ? commaFormat(item.stocks_sum_profit_value) : '' }}</td>
                    </tr>
                    <tr v-if="currentPage === lastPage">
                      <td colspan="3" class="text-right">Total</td>
                      <td v-if="hasBusinessName"></td>
                      <td v-if="salePersonName"></td>
                      <td v-if="contactName"></td>
                      <td class="text-right"> {{ totalValue && totalValue.total_subtotal_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_vat_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_sum_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_discount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_paid_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_due_amount }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_stock_value }}</td>
                      <td class="text-right"> {{ totalValue && totalValue.total_profit_value }}</td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import {computed} from "vue";
import figureFormatter from "@/services/utils/figureFormatter";

const {commaFormat} = figureFormatter();

const props = defineProps({
    tableItems: {
        type: Array,
    },
    currentPage: Number,
    lastPage: Number,
    perPage: Number,
    totalValue: Object,
})

const hasBusinessName = computed(() => {
  return props.tableItems.some(item => item.business_name && item.business_name.trim() !== '');
});

const salePersonName = computed(() => {
  return props.tableItems.some(item => item.sr_name && item.sr_name.trim() !== '');
});

const contactName = computed(() => {
  return props.tableItems.some(item => item.contact_name && item.contact_name.trim() !== '');
});

const getTotalDue = (item) => {
    return parseFloat(item.total_amount) - parseFloat(item.net_receipt_amount) - parseFloat(item.ait_deducted) - parseFloat(item.vat_deducted) - parseFloat(item.discount)
}
</script>

<style scoped>
.mr-5 {
    margin-right: 5px;
}
.action-th {
    width: 20%;
    text-align: center;
}
.name {
    width: 15%;
}
.action{
    text-align: center;
}

</style>